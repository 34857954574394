<template>
  <div class="box bunya-box">
    <div class="">
      <div class="level is-mobile component-level-margin">
        <div class="level-left">
          <div class="level-item">
            <h6 class="has-opacity">{{ payment.reference }}</h6>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <h6 class="dashboard-date has-opacity">PAID {{ payment.paid_at | moment('D MMM YYYY') }}</h6>
          </div>
        </div>
      </div>
      <div class="level is-mobile">
        <div class="level-left">
          <div class="block level-item">
            <h4 class="no-line-height has-dashboard-limit">{{ payment.payment_method.name }}</h4>
            <h4 class="no-line-height">{{ payment.amount | currency }}</h4>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <button class="button is-light has-icon" @click="deletePayment">
              <icon icon="bin" stroke="#FFFFFF" :width="20" :height="20"/>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Icon from '@/components/Icons/Icon'

export default {
  components: { Icon },
  methods: {
    deletePayment () {
      this.$store.dispatch('invoices/deletePayment', this.payment.index)
        .then(() => {
          this.$buefy.toast.open({
            message: 'Payment deleted.',
            type: 'is-success'
          })
        })
    }
  },
  name: 'InvoicePaymentCard',
  props: ['payment']
}
</script>
