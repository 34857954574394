<template>
  <section>
    <div class="box bunya-box" style="margin-top: 30px;" v-if="invoice">
      <br>
      <div class="level">
        <div class="level-left">
          <div class="level-item">

          </div>
        </div>
        <div class="level-right is-hidden-mobile" v-if="brandLogoUrl">
          <div class="level-item">

          </div>
        </div>
      </div>
      <div class="table">
        <div class="columns">
          <div class="column is-3">
            <span class="client-name" v-if="invoice.created_at">{{ invoice.client.name }}</span>
            <b-field label="Client" v-else>
              <b-select placeholder="Select a client" v-model="invoice.client_id" @change.native="updateClient">
                <optgroup label="Options">
                  <option :value="-1">Create New Client</option>
                </optgroup>
                <optgroup label="Existing Clients">
                  <option
                    v-for="client in clients"
                    :value="client.id"
                    :key="client.id">
                    {{ client.name }}
                  </option>
                </optgroup>
              </b-select>
            </b-field>
          </div>
          <div class="column is-6"></div>
          <div class="column is-3">
            <h5 :class="invoiceStatusClass">{{ invoice.status }}</h5>
          </div>
        </div>
      </div>
      <div class="table-controller-area">
        <div class="columns">
          <div class="column is-3">
            <b-field label="Invoice Date">
              <b-datepicker
                v-model="invoice.billedAt"
                placeholder="Billed Date"
                icon="calendar-today"
                trap-focus
                :disabled="isFinalized">
              </b-datepicker>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label="Payment Due">
              <b-datepicker
                v-model="invoice.dueAt"
                placeholder="Billed Date"
                icon="calendar-today"
                trap-focus
                :disabled="isFinalized">
              </b-datepicker>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label="Enter amounts">
              <b-select placeholder="Select Tax Mode" v-model="invoice.tax_inclusive" @change.native="updateTaxInclusiveStatus" expanded :disabled="isFinalized">
                <option :value="true">Inclusive Of Tax</option>
                <option :value="false">Exclusive Of Tax</option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-3" v-if="useMultipleCurrencies">
            <b-field label="Currency">
              <b-select class="has-text-right" placeholder="Select a currency" v-model="invoice.currency_id" :disabled="invoice.finalized">
                <option
                  v-for="currency in currencies"
                  :value="currency.id"
                  :key="currency.id">
                  {{ currency.name }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
      </div>
      <div class="b-table">
        <div class="table-wrapper has-mobile-cards">
          <table class="table is-fullwidth">
            <thead class="invoice-editor-table-header">
              <th>Description</th>
              <th class="has-text-right">Quantity</th>
              <th class="has-text-right">Unit Price</th>
              <th class="has-text-right">Tax Rate</th>
              <th class="has-text-right">Total</th>
              <th class="is-hidden-tablet"></th>
            </thead>
            <tbody>
            <tr
              :class="itemRowClass"
              v-for="(item, index) in invoiceItems"
              :key="index"
              @click="$emit('edit', { itemIndex: item.index, taxInclusive: invoice.tax_inclusive })"
            >
              <td class="has-text-left is-empty" v-if="item.description">{{ item.description }}</td>
              <td class="has-text-left is-empty" v-if="!item.description">Click to edit.</td>
              <td class="has-text-right" data-label="Quantity">{{ item.quantity }}</td>
              <td class="has-text-right" data-label="Unit Price">{{ item.unit_price }}</td>
              <td class="has-text-right" data-label="Tax Rate">{{ item.tax_rate.name }}</td>
              <td class="has-text-right" data-label="Total Price">{{ item.total_price }}</td>
            </tr>
            </tbody>
            <tfoot>
              <tr class="middle-row">
                <th colspan="3"></th>
                <th class="has-text-right">Tax</th>
                <th class="has-text-right">{{ tax }}</th>
              </tr>
              <tr class="middle-row invoice-total-row">
                <th colspan="3"></th>
                <th class="has-text-right"><h4 class="grand-total">Total</h4></th>
                <th class="has-text-right"><h4 class="grand-total">{{ total }}</h4></th>
              </tr>
              <tr class="middle-row invoice-payment-status-row">
                <th colspan="3" class="invoice-total-ghost"></th>
                <th class="has-text-right">Amount Paid</th>
                <th class="has-text-right">{{ paid }}</th>
              </tr>
              <tr class="middle-row invoice-payment-status-row">
                <th>
                  <button class="button is-primary is-hidden-mobile" v-if="!isFinalized"
                          @click="$emit('add', { itemIndex: null, taxInclusive: invoice.tax_inclusive })">Add New Item
                  </button>
                </th>
                <th colspan="2"></th>
                <th class="has-text-right">Amount Owing</th>
                <th class="has-text-right">{{ unpaid }}</th>
              </tr>
              <tr class="is-hidden-tablet" v-if="!isFinalized">
                <th colspan="5">
                  <button class="button is-primary is-fullwidth"
                          @click="$emit('add', { itemIndex: null, taxInclusive: invoice.tax_inclusive })">Add New Item
                  </button>
                </th>
              </tr>
             <tr v-else><th colspan="5">{{ invoice.currency_conversion_message }}</th></tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'EditorTable',
  components: {},
  computed: {
    itemRowClass () {
      return {
        'invoice-item-row': true,
        'can-edit': !this.isFinalized
      }
    },
    taxRates () {
      return this.$store.getters['taxRates/taxRates']
    },
    clients () {
      return this.$store.getters['clients/all']
    },
    isFinalized () {
      return this.$store.getters['invoices/invoiceFinalized']
    },
    subtotal () {
      return this.$store.getters['invoices/invoiceSubtotal']
    },
    tax () {
      return this.$store.getters['invoices/invoiceTaxTotal']
    },
    total () {
      return this.$store.getters['invoices/invoiceTotal']
    },
    paid () {
      return this.$store.getters['invoices/invoicePaidTotal']
    },
    unpaid () {
      return this.$store.getters['invoices/invoiceUnpaidTotal']
    },
    invoiceItems () {
      return this.$store.getters['invoices/invoiceItems']
    },
    brandLogoUrl () {
      return this.$store.getters['account/brandLogoUrl']
    },
    useMultipleCurrencies () {
      return this.$store.getters['account/useMultipleCurrencies']
    },
    currencies () {
      return this.$store.getters['currencies/currencies']
    },
    invoiceStatusClass () {
      if (!this.invoice.id) {
        return {
          status: true,
          'is-draft': true
        }
      }
      return {
        status: true,
        'is-paid': this.invoice.status === 'Paid',
        'is-unpaid': this.invoice.status === 'Unpaid',
        'is-overdue': this.invoice.status === 'Overdue',
        'is-draft': this.invoice.status === 'Draft',
        'is-emailed': this.invoice.status === 'Emailed'
      }
    }
  },
  mounted () {
    this.$store.dispatch('account/fetch')
      .then(() => {
        this.$store.dispatch('clients/fetchAll')
          .then(() => {
            if (this.preselectedClientId) {
              this.updateClient(null)
            }
          })
      })
  },
  methods: {
    updateTaxInclusiveStatus () {
      this.$store.dispatch('invoices/updateTaxInclusiveStatus', this.invoice.tax_inclusive)
    },
    showClientCreate () {
      this.$emit('client-create')
    },
    updateClient () {
      const invoiceClientId = this.invoice.client_id
      if (this.invoice.client_id === -1) {
        this.showClientCreate()
        this.clientId = null
        return true
      }
      if (!this.useMultipleCurrencies) {
        return true
      }
      const selectedClient = this.clients.find(client => {
        return client.id === invoiceClientId
      })
      if (selectedClient.default_currency_id) {
        this.invoice.currency_id = selectedClient.default_currency_id
      }
    }
  },
  props: ['invoice', 'preselectedClientId']
}
</script>

<style lang="scss" scoped>

  @import "../scss/_theme-default.scss";

  .brand-logo {
    max-width: 200px;
    max-height: 50px;
  }

  .status {
    margin-top: 5px;
    border-radius: 30px;
    color: #12133D;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    text-transform: uppercase;
    letter-spacing: 3px;
    background: #E5E8F0;
    border: 3px solid rgba(18,19,61,0.10);
  }

  .is-paid {
    background-color: $success
  }
  .is-overdue {
    background-color: $danger
  }
  .is-unpaid {
    background-color: $warning
  }
  .is-draft {
    background-color: $light
  }
  .is-emailed {
    background-color: $info
  }

  .description {
    text-align: left !important;
  }

  .invoice-item-row {
    border: 1px #ccc solid;
    margin-top: 20px;
  }

  .invoice-item-row:nth-child(even) {
    background-color: #fff;
  }

  .invoice-item-row:nth-child(odd) {
    background-color: #fff;
  }

  .can-edit.invoice-item-row:hover {
    background-color: #389cff25;
    cursor: pointer;
  }

  .grand-total {
    font-size: 14pt;
    font-weight: 800;
    color: #12133D;
  }

  hr {
    margin-left: -20px;
    margin-right: -20px;
  }

  .client-name {
    color: #2D3958;
    font-size: 28px;
    font-weight: 400;
    line-height: 32px;
  }

  .invoice-editor-table-header th {
    border-bottom: 2px rgba($dark-blue, 0.6) solid;
  }

  .invoice-total-row th {
    border-bottom: 1px rgba($dark-blue, 0.4) solid;
    border-top: 2px rgba($dark-blue, 0.4) solid;
  }

  .invoice-payment-status-row th {
    background-color: rgba($dark-blue, 0.01);
  }

  .table-controller-area {
    background-color: #fafafa;
    margin: 0 -20px 20px -20px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-left: none;
    border-right: none;
    display: flex;

    .columns {
      width: 100%;
      padding-left: 20px;
    }

  }

  .is-inline {
    display: flex !important;
    align-items: center;

    .icon-container {
      padding-right: 5px;
    }
  }

  .is-flex {
    display: flex;
  }

  .fake-input-text-right {
    justify-content: right;
  }

</style>
