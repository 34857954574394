<template>
  <div class="modal-card">
    <header class="modal-card-head modal-header">
      <h1 class="header-text">Create Client</h1>
    </header>
    <section class="modal-card-body">
      <div class="columns is-mobile">
        <div class="column is-12 form-field">
          <b-field class="modal-label" label="Name" :message="(errors['client.name']) ? (errors['client.name'][0]) : null" :type="(errors['client.name']) ? 'is-danger' : null">
            <b-input ref="clientQuickCreateName" type="text" v-model="client.name"></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column is-12 form-field">
          <b-field class="modal-label" label="Email Address" :message="(errors['client.email']) ? (errors['client.email'][0]) : null" :type="(errors['client.email']) ? 'is-danger' : null">
            <b-input ref="clientQuickCreateName" type="text" v-model="client.email"></b-input>
          </b-field>
        </div>
      </div>
<!--      <div class="columns" v-if="false">-->
<!--        <div class="column is-6 form-field" v-if="useMultipleCurrencies">-->
<!--          <b-field class="modal-label" label="Currency" :message="(errors['client.default_currency_id']) ? (errors['client.default_currency_id'][0]) : null" :type="(errors['client.default_currency_id']) ? 'is-danger' : null">-->
<!--            <b-select placeholder="Select Currency" required expanded v-model="client.default_currency_id">-->
<!--              <option v-for="(currency, index) in currencies" :key="index" :value="currency.id">{{ currency.name }}</option>-->
<!--            </b-select>-->
<!--          </b-field>-->
<!--        </div>-->
<!--        <div class="column is-6 form-field">-->
<!--          <b-field class="modal-label" label="Language" :message="(errors['client.default_language_id']) ? (errors['client.default_language_id'][0]) : null" :type="(errors['client.default_language_id']) ? 'is-danger' : null">-->
<!--            <b-select placeholder="Billing Language" required expanded v-model="client.default_language_id">-->
<!--              <option v-for="(language, index) in languages" :key="index" :value="language.id">{{ language.name }}</option>-->
<!--            </b-select>-->
<!--          </b-field>-->
<!--        </div>-->
<!--      </div>-->
    </section>
    <footer class="modal-card-foot">
      <button class="button is-light" type="button" @click="cancel">Close</button>
      <button class="button is-primary" @click="saveClient" :disabled="isSaveDisabled">Save</button>
    </footer>
  </div>
</template>

<style lang="scss">

@import "../scss/_theme-default.scss";

.green-outline input {
  border: 1px green solid;
}

.item-subtotal {
  font-weight: 800;
  font-size: 24pt;
  color: $dark;
  margin-right: 20px;
}

</style>

<script>

export default {
  name: 'ClientQuickCreate',
  data () {
    return {
      client: {
        id: null,
        name: null,
        email: null,
        type: 'individual'
      },
      errors: {
        name: null,
        email: null
      }
    }
  },
  methods: {
    saveClient () {
      this.$store.dispatch('clients/save', this.client)
        .then((response) => {
          this.$emit('client-created', response.data)
          this.$parent.close()
        })
        .catch((e) => {
          if (e.response.data.errors) {
            console.log('there are errors')
            this.errors = e.response.data.errors
          } else {
            this.$buefy.toast.open({
              message: `Error: ${e.message}`,
              type: 'is-danger',
              duration: 4000
            })
          }
        })
    },
    cancel () {
      this.$emit('client-create-cancelled')
      this.$parent.close()
    }
  },
  computed: {
    isSaveDisabled () {
      if (!this.client.name) {
        return true
      }
      if (!this.client.email) {
        return true
      }
      return false
    },
    currencies () {
      return this.$store.getters['currencies/currencies']
    },
    languages () {
      return this.$store.getters['languages/languages']
    },
    useMultipleCurrencies () {
      return this.$store.getters['account/useMultipleCurrencies']
    }
  },
  mounted () {},
  components: {}
}
</script>
