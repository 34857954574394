<template>
  <div class="modal-card">
    <header class="modal-card-head modal-header">
      <p class="modal-header-text">{{ title }}</p>
    </header>
    <section class="modal-card-body">
      <div class="columns">
        <div class="column is-8">
          <b-field class="modal-label" label="Payment method">
            <b-select placeholder="Select payment method" v-model="paymentMethodId" required expanded>
              <!-- TODO Fetch Payment Methods From Store / API -->
              <option value="2">Cash</option>
              <option value="3">Credit Card / EFTPOS</option>
              <option value="4">Bank Transfer</option>
              <option value="5">Direct Debit</option>
              <option value="6">Cheque</option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field class="modal-label" label="Payment amount">
            <b-input icon="currency-usd" type="number" step="0.01" v-model="paymentAmount"></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column is-4">
          <b-field class="modal-label" label="Payment Date">
            <b-datepicker placeholder="Click to select..." v-model="paymentDate" icon="calendar-today" position="is-top-right" trap-focus
                          editable></b-datepicker>
          </b-field>
        </div>
        <div class="column is-8">
          <b-field class="modal-label" label="Reference">
            <b-input placeholder="(Optional)" v-model="paymentReference" expanded></b-input>
          </b-field>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot modal-footer is-right">
      <button class="button is-light" type="button"  @click="$parent.close()">Close</button>
      <button class="button is-primary" :disabled="isInvalid" @click="save">Save</button>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'RecordPayment',
  data () {
    return {
      paymentMethodId: null,
      paymentAmount: 0,
      paymentDate: null,
      paymentReference: ''
    }
  },
  computed: {
    isInvalid () {
      if (!this.paymentMethodId) {
        return true
      }
      if (this.amount === 0) {
        return true
      }
      if (!this.paymentAmount) {
        return true
      }
      if (!this.paymentDate) {
        return true
      }
      return false
    },
    payload () {
      return {
        paymentMethodId: this.paymentMethodId,
        paymentAmount: this.paymentAmount,
        paymentDate: this.paymentDate,
        paymentReference: this.paymentReference
      }
    }
  },
  methods: {
    save () {
      if (this.isInvalid) {
        return false
      }
      this.$emit('record-payment', this.payload)
      this.$parent.close()
    }
  },
  mounted () {
    this.paymentAmount = this.unpaidTotal
    this.paymentDate = new Date()
  },
  props: ['title', 'unpaidTotal']
}
</script>
