<template>
  <div class="modal-card">
    <header class="modal-card-head modal-header">
      <p class="modal-header-text">{{ title }}</p>
    </header>
    <section class="modal-card-body">
      <div class="subsection">
        <p>If you'd like your customer's to be able to pay you with a credit card you can <a @click="configureStripe">connect a Stripe account</a>. They're quick to set up. Customers usually pay faster when you offer credit card as a payment method.</p>
        <p>You can also <a @click="configureInvoiceSettings">add alternative payment instructions</a> where you can list bank details for deposits, cheque instructions, or add details about other electronic payment providers like PayPal or Venmo.</p>
        <p>If you'd like to send the invoice without payment instructions you can still do so.</p>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-light" type="button" @click="$parent.close()">Close</button>
      <button class="button is-info" @click="sendAnyway">Send Anyway</button>
    </footer>
  </div>
</template>

<style>

h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.subsection {
  padding-bottom: 10px;
}

.modal-card-foot {
  justify-content: space-between;
}

</style>

<script>

export default {
  data () {
    return {}
  },
  components: {},
  computed: {},
  methods: {
    sendAnyway () {
      this.$emit('send-anyway', {})
      this.$parent.close()
    },
    configureStripe () {
      this.$router.push({ name: 'settings-stripe' })
      this.$parent.close()
    },
    configureInvoiceSettings () {
      this.$router.push({ name: 'settings-invoice-settings' })
      this.$parent.close()
    }
  },
  name: 'InvoicePaymentSetupWarning',
  mounted () {},
  props: ['title']
}
</script>
