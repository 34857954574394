<template>
  <section class="section has-bunya-max-width">
    <subpage-header
      v-if="invoice"
      title="Invoice Editor"
      breadcrumb="Invoices"
      subtitle-icon="accounting"
      :subtitle="subtitle"
      :primary-action="primaryAction"
      :secondary-actions="secondaryActions"
      :tertiary-actions="tertiaryActions"
      v-on:breadcrumb="showInvoices"
      v-on:show-invoice-mailer-panel="showInvoiceMailer"
      v-on:show-invoice-payment-panel="showInvoicePayment"
      v-on:show-invoice-delete-panel="showDeleteInvoice"
      v-on:show-invoice-payment-history="showInvoicePaymentHistory"
      v-on:finalize="finalize"
      v-on:unfinalize="unfinalize"
      v-on:download-pdf="downloadPdf"
      v-on:save="save"
    ></subpage-header>
    <!-- for desktop -->
    <div class="columns subpage-information-area-margin is-hidden-touch">
      <div class="column">
        <invoice-editor-table v-on:add="showItemEdit" v-on:edit="showItemEdit" v-on:client-create="showClientCreate" :invoice="invoice" :preselected-client-id="preselectedClientId"></invoice-editor-table>
      </div>
    </div>
    <!-- for mobile -->
    <div class="columns subpage-information-area-margin is-hidden-desktop" style="padding-left: 27px;padding-right: 27px;">
      <div class="column" style="padding-left: 0px; padding-right: 0px;">
        <invoice-editor-table v-on:add="showItemEdit" v-on:edit="showItemEdit" :invoice="invoice"></invoice-editor-table>
      </div>
    </div>
  </section>
</template>

<script>

import SubpageHeader from '@/components/SubpageHeader'
import InvoiceEditorTable from '@/components/InvoiceEditorTable'
import RecordPayment from '@/components/RecordPayment'
import DeleteInvoice from '@/components/DeleteInvoice'
import SendMail from '@/components/SendMail'
import InvoicePaymentSetupWarning from '@/components/InvoicePaymentSetupWarning'
import InvoicePaymentHistory from '@/components/InvoicePaymentHistory'
import InvoiceItemEditor from '@/components/InvoiceItemEditor'
import ClientQuickCreate from '@/components/ClientQuickCreate'
import moment from 'moment'

export default {
  name: 'InvoiceEdit',
  components: { SubpageHeader, InvoiceEditorTable },
  computed: {
    isFinalized () {
      return this.$store.getters['invoices/invoiceFinalized']
    },
    isMobile () {
      return this.$isMobile()
    },
    isPayable () {
      return this.$store.getters['account/isPayable']
    },
    subtitle () {
      if (this.invoice.id) {
        return this.invoice.invoice_number_formatted
      }
      return 'Create new invoice.'
    },
    defaultMessageBody () {
      let body = ''
      body += '<p>Here is your invoice <strong>' + this.invoice.invoice_number_formatted + '</strong>.</p>'
      body += '<p>Payment of <strong>' + this.invoiceUnpaidTotal + '</strong> is due by '
      body += '<strong>' + moment(this.invoice.dueAt).format('D MMM YYYY') + '</strong>.</p>'
      return body
    },
    clientContacts () {
      const baseContact = {
        name: this.invoice.client.name,
        email: this.invoice.client.email
      }
      if (this.invoice.client.type === 'business' && this.invoice.client.contact_person) {
        baseContact.name = this.invoice.client.contact_person
      }
      let clientContacts = []
      if (baseContact.name && baseContact.email) {
        clientContacts = [baseContact]
      }
      if (this.invoice.client.additional_contacts) {
        clientContacts = clientContacts.concat(this.invoice.client.additional_contacts)
      }
      return clientContacts
    },
    invoice () {
      return this.$store.getters['invoices/invoice']
    },
    invoiceTaxTotal () {
      return this.$store.getters['invoices/invoiceTaxTotal']
    },
    invoiceUnpaidTotal () {
      return this.$store.getters['invoices/invoiceUnpaidTotal']
    },
    isView () {
      return (!this.enableEditing)
    },
    isEdit () {
      return (this.enableEditing)
    },
    isCreate () {
      if (this.id === 'create') {
        return true
      }
      return false
    },
    primaryAction () {
      if (!this.isFinalized) {
        return {
          name: 'Approve',
          icon: 'check',
          emit: 'finalize'
        }
      }
      return {
        name: 'Send Invoice',
        icon: 'paperClip',
        emit: 'show-invoice-mailer-panel'
      }
    },
    secondaryActions () {
      const secondaryActions = []
      if (this.isFinalized) {
        secondaryActions.push(
          {
            name: 'Record Payment',
            icon: 'recordPayment',
            emit: 'show-invoice-payment-panel'
          }
        )
        secondaryActions.push({
          name: 'Download PDF',
          icon: 'downloadDocument',
          emit: 'download-pdf'
        })
      }
      if (!this.isFinalized) {
        secondaryActions.push({
          name: 'Save Draft',
          link: null,
          icon: 'alert',
          emit: 'save'
        })
      }
      return secondaryActions
    },
    tertiaryActions () {
      const tertiaryActions = []
      if (this.isFinalized) {
        tertiaryActions.push({
          name: 'Make Changes',
          icon: 'edit',
          emit: 'unfinalize'
        })
      } else {
        tertiaryActions.push({
          name: 'Delete Invoice',
          description: 'Permanently Remove',
          icon: 'bin',
          emit: 'show-invoice-delete-panel'
        })
      }
      tertiaryActions.push({
        name: 'Payment History',
        icon: 'income',
        emit: 'show-invoice-payment-history'
      })
      return tertiaryActions
    }
  },
  methods: {
    downloadPdf () {
      window.open(this.invoice.pdf_link)
    },
    showClientCreate () {
      const invoice = this.invoice
      this.$buefy.modal.open({
        parent: this,
        component: ClientQuickCreate,
        props: {
          title: 'Create Client'
        },
        fullScreen: this.isMobile,
        events: {
          'client-created': (client) => {
            invoice.client_id = client.id
          },
          'client-create-cancelled': () => {
            invoice.client_id = null
          }
        },
        hasModalCard: true,
        trapFocus: true
      })
    },
    showInvoices () {
      this.$router.push({ name: 'invoices' })
    },
    showInvoiceMailer () {
      if (this.isPayable) {
        this.openInvoiceMailModal()
      } else {
        this.openPaymentSetupModal()
      }
    },
    openPaymentSetupModal () {
      this.$buefy.modal.open({
        parent: this,
        component: InvoicePaymentSetupWarning,
        props: {
          title: 'No Payment Details Configured'
        },
        fullScreen: this.isMobile,
        events: {
          'send-anyway': () => {
            this.openInvoiceMailModal()
          }
        },
        hasModalCard: true,
        trapFocus: true
      })
    },
    openInvoiceMailModal () {
      this.$buefy.modal.open({
        parent: this,
        component: SendMail,
        props: {
          title: 'Send Invoice ' + this.invoice.invoice_number_formatted,
          emailAddresses: this.clientContacts,
          defaultMessageBody: this.defaultMessageBody
        },
        fullScreen: this.isMobile,
        events: {
          'send-email': (payload) => {
            payload.invoiceId = this.invoice.id
            this.sendEmail(payload)
          }
        },
        hasModalCard: true,
        trapFocus: true
      })
    },
    sendEmail (payload) {
      this.$store.dispatch('invoices/sendInvoiceEmail', payload)
        .then(() => {
          this.$buefy.toast.open({
            message: 'Email sent.',
            type: 'is-success'
          })
        })
        .catch((e) => {
          this.$buefy.toast.open({
            message: `Error: ${e.response.data.message}`,
            type: 'is-danger'
          })
        })
    },
    showInvoicePaymentHistory () {
      this.$buefy.modal.open({
        parent: this,
        component: InvoicePaymentHistory,
        props: {
          invoice: this.invoice
        },
        fullScreen: this.isMobile,
        events: {
          'delete-payment': (invoicePaymentId) => {
            this.deletePayment(invoicePaymentId)
          }
        },
        hasModalCard: true,
        trapFocus: true
      })
    },
    deletePayment (invoicePaymentId) {
      this.$store.dispatch('invoices/deletePayment', invoicePaymentId)
        .then(() => {
          this.$buefy.toast.open({
            message: 'Payment deleted.',
            type: 'is-success'
          })
        })
    },
    recordPayment (payload) {
      payload.invoiceId = this.invoice.id
      this.$store.dispatch('invoices/recordPayment', payload)
        .then(() => {
          this.$buefy.toast.open({
            message: 'Payment recorded.',
            type: 'is-success'
          })
        })
    },
    showInvoicePayment () {
      this.$buefy.modal.open({
        parent: this,
        component: RecordPayment,
        props: {
          title: 'Record payment for ' + this.invoice.invoice_number_formatted,
          unpaidTotal: this.invoiceUnpaidTotal
        },
        events: {
          'record-payment': (payload) => {
            this.recordPayment(payload)
          }
        },
        fullScreen: this.isMobile,
        hasModalCard: true,
        trapFocus: true
      })
    },
    showDeleteInvoice () {
      this.$buefy.modal.open({
        parent: this,
        component: DeleteInvoice,
        props: {
          invoice: this.invoice
        },
        fullScreen: this.isMobile,
        hasModalCard: true,
        trapFocus: true
      })
    },
    showItemEdit (payload) {
      if (!this.isFinalized) {
        this.$buefy.modal.open({
          parent: this,
          component: InvoiceItemEditor,
          props: payload,
          hasModalCard: true,
          trapFocus: true,
          fullScreen: this.isMobile
        })
      }
    },
    finalize () {
      this.invoice.finalized = true
      this.save()
    },
    unfinalize () {
      this.invoice.finalized = false
      this.save()
    },
    save () {
      this.$store.dispatch('invoices/saveInvoice')
        .then((response) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: 'Invoice saved.',
            type: 'is-success'
          })
          if (response.isNew) {
            this.$router.push({ name: 'invoices-show', params: { id: response.invoice.id } })
          }
        })
        .catch((e) => {
          const errors = e.response.data.errors
          this.isLoading = false
          this.invoice.finalized = false
          if (errors) {
            Object.keys(errors).forEach((errorKey) => {
              this.$buefy.toast.open({
                message: errors[errorKey][0],
                type: 'is-danger',
                duration: 4000
              })
            })
          } else {
            this.$buefy.toast.open({
              message: `Error: ${e.message}`,
              type: 'is-danger',
              duration: 4000
            })
          }
        })
    }
  },
  mounted () {
    this.$store.dispatch('taxRates/fetch')
    this.$store.dispatch('currencies/fetch')
    this.$store.dispatch('user/fetch')
    if (this.id) {
      this.$store.dispatch('invoices/fetchInvoice', { invoiceId: this.id })
    } else {
      this.$store.dispatch('invoices/resetInvoice')
    }
    if (!this.invoice.id && this.preselectedClientId) {
      this.invoice.client_id = this.preselectedClientId
    }
  },
  props: ['id', 'preselectedClientId']
}

</script>
