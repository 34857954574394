<template>
  <div class="modal-card">
    <header class="modal-card-head modal-header">
      <p class="modal-header-text">Payment History For Invoice {{ this.invoice.invoice_number_formatted }}</p>
    </header>
    <section class="modal-card-body">
      <div v-if="payments.length">
        <invoice-payment-card v-for="(payment, index) in payments" v-bind:key="index" :payment="payment"></invoice-payment-card>
      </div>
      <div v-else>
        <p>No payments recorded for this invoice.</p>
      </div>
    </section>
    <footer class="modal-card-foot modal-footer is-right">
      <button class="button is-light" type="button"  @click="$parent.close()">Close</button>
    </footer>
  </div>
</template>

<script>

import InvoicePaymentCard from '@/components/InvoicePaymentCard.vue'

export default {
  computed: {
    payments () {
      return this.$store.getters['invoices/invoicePayments']
    }
  },
  components: { InvoicePaymentCard },
  name: 'InvoicePaymentHistory',
  props: ['invoice']
}
</script>
